<template>
    <content-view v-if="isPageLoaded">
        <div class="lesson content-centered" v-if="!isLoading">
            <div
                class="lesson__content"
            >
                <router-link :to="`/courses/${courseId}`" class="v-back">
                    <i class="v-back__icon"/>
                    К оглавлению
                </router-link>
                <div class="lesson__body">
                    <span class="v-desc lesson__meta">{{ lesson.description }}</span>
                    <new-editor
                    class="content__editor"
                    :data="lesson.text"
                    :editable="false"
                    top-placeholder="Название урока"
                    bottom-placeholder="Описание урока"
                />
                </div>
                <div class="lesson__controls" v-if="lesson">
                    <div v-if="!isEditorCourse && lesson.access" class="lesson__controls-ready">
                        <button
                            class="v-btn"
                            :class="{
                                'v-btn--ready': lesson.studied,
                                'v-btn--not-ready': !lesson.studied,
                            }"
                            @click="getReady"
                        >
                            <icon>
                                <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 6.32895L5.30519 11L14 1" stroke="white" stroke-width="2"/>
                                </svg>
                            </icon>
                            <span class="ml-10">Изучено</span>
                        </button>
                    </div>
                    <div class="lesson__controls-nav">
                        <button @click="prevLesson" :disabled="isPrevBtnDisabled" class="v-btn v-btn--white">
                            <svg class="mr-10" width="8" height="12" viewBox="0 0 8 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L2 6L7 11" stroke="#9D9D9E" stroke-width="2"/>
                            </svg>
                            <span>Предыдущий урок</span>
                        </button>
                        <button @click="nextPage"
                                v-if="lesson.next && lesson.next.lesson"
                                :disabled="isNextBtnDisabled || lesson.next.test_passed" class="v-btn v-btn--white ml-10">
                            <span>Следующий урок</span>
                            <svg class="ml-10" width="8" height="12" viewBox="0 0 8 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6 6L1 11" stroke="#9D9D9E" stroke-width="2"/>
                            </svg>
                        </button>
                        <button @click="nextPage"
                                v-if="lesson.next && lesson.next.test && !isEditorCourse"
                                :disabled="isNextBtnDisabled || lesson.next.test_passed" class="v-btn v-btn--white ml-10">
                            <span>Перейти к тесту</span>
                            <svg class="ml-10" width="8" height="12" viewBox="0 0 8 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6 6L1 11" stroke="#9D9D9E" stroke-width="2"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <comments-block
                    v-if="lesson.comments_allowed"
                    :update-comments-count="() => lesson.comments.count++"
                    :comments-list="lesson.comments"
                    content-type="lesson"
                    :obj-id="lesson.id"
                    class="lesson__comments"
                />
            </div>
        </div>
        <loading-spinner v-else />
    </content-view>
</template>

<script>
import session from "@/api/session";
import { mapGetters } from "vuex";
import { eventBus } from '@/main';

export default {
    name: "lessonDetail",
    components: {
        CommentsBlock : () => import('@/components/comment/CommentsBlock'),
        Comments : () => import('@/components/wall/Comments/Comments'),
        Icon : () => import('@/components/icon/Icon'),
        ContentView : () => import('@/views/menu/ContentView'),
        NewEditor : () => import('@/components/editor/newEditor'),
        LoadingSpinner : () => import('@/components/LoadingSpinner')
    },
    data() {
        return {
            lesson: {},
            courseId: this.$route.params.course_id,
            isPageLoaded: false,
            isLoading: true
        };
    },
    computed: {
        ...mapGetters("default_data", ["isAdmin", 'isEditorCourse']),
        isNextBtnDisabled() {
            if (!this.lesson.next) {
                return true
            }
            return this.isEditorCourse ? false : !this.lesson.studied;
        },
        isPrevBtnDisabled() {
            return !this.lesson.previous
        }
    },
    async created() {
        await this.loadlesson();
    },
    mounted () {
        this.$nextTick(() => {
            this.isPageLoaded = true;
        })
    },
    methods: {
        async getReady() {
            if (!this.lesson.studied) {
                const request = await session.post(`/api/v1/course-lesson/${this.lesson.id}/studied/`);
                this.lesson.studied = true;
                if (request.data.is_finished) {
                    this.$router.push(`/courses/${this.courseId}`);
                    let finishModal = () => import(`@/components/course/modals/ModalCourseFinish`);
                    this.$modal.show(finishModal, {
                    }, {
                        name: 'course-finish',
                        scrollable: true,
                        height: 'auto',
                        adaptive: true,
                        maxWidth: 540,
                        width: '100%',
                    })
                }
            }
        },
        async loadlesson() {
            let response;
            try {
                this.isLoading = true;
                const request = session.get(`/api/v1/course-lesson/${this.$route.params.lesson_id}/`);
                response = await request;
                this.lesson = response.data;
                this.isLoading = false;
            } catch (err) {
                if (err.response.status === 404) {
                    this.$router.push(`/404`);
                }
            }
        },
        nextPage() {
            const {lesson} = this;
            //Проблемы конченой типизации JS
            if (this.isEditorCourse && !!lesson.next.lesson || lesson.studied && !!lesson.next.lesson) {
                this.$router.push(`/courses/${this.courseId}/lesson/${lesson.next.lesson}`)
            } else if (this.isEditorCourse && !!lesson.next.test || lesson.studied && !!lesson.next.test) {
                this.$router.push(`/courses/${this.courseId}/test/${lesson.next.test}`)
            }
        },
        prevLesson() {
            if (this.lesson.previous) {
                this.$router.push(`/courses/${this.courseId}/lesson/${this.lesson.previous}`)
            }
        },
        removelesson() {
            eventBus.$emit('remove-lesson', this.lesson);
        }
    },
};
</script>

<style lang="scss">
@import "#sass/v-style";
@import "detail";
.content-centered {
    max-width: 800px;
    margin: 0 auto;

    @include below($tablet) {
        max-width: 100%;
    }
}
.lesson {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    height: 100%;

    @include below(1000px) {
        padding: 0 35px;
    }

    ul,
    ol {
        padding-left: 1rem !important;
    }

    &__body {
        h1 {
            font-weight: 700;
            font-size: rem(40px);
        }
    }
    &__meta {
        margin-top: 10px;
    }
    &__text {
        margin-top: 20px;
    }
    &__content {
        padding-bottom: 16px;
        ul {
            list-style: disc;
        }
        p {
            margin-bottom: 10px !important;
        }
    }
    &__controls {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        &-nav {
            display: flex;
            align-items: center;
            button {
                display: flex;
                align-items: center;
                border-radius: 4px !important;
                border: 1px solid #DCDCDC !important;
            }
        }
        .v-btn--not-ready {
            path {
                stroke: #50B440
            }
        }
    }
    &__comments {
        margin-top: 30px;
    }
}
</style>
